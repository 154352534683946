import { useEffect, useState } from "react";
import { auth, fbTimestamp, fbUI, fbUIAuth } from "../utilities/Firebase";
import { OAuthProvider, signInWithPopup } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import "firebaseui/dist/firebaseui.css";
import "./PageStyles.css";
import { createDoc, loading, isMobile } from "../utilities/Functions";
import { AuthenticateWithTwitch } from "../utilities/TwitchLoginFunctions";
import AppStoreButtonLogo from "../assets/Download_on_the_App_Store_Badge_blk.svg";
import GooglePlayStoreButtonLogo from "../assets/GetItOnGooglePlay_Badge_Web_color_English.svg";
import {Image} from "react-bootstrap";

import Icon_twitch from "../assets/Icon_twitch.svg";
import Icon_apple_black from "../assets/Icon_apple_black.svg";

const Login = () => {
  const location = useLocation();

  const [status, setStatus] = useState();

  const [pending, setPending] = useState();
  const [error, setError] = useState();

  const [updatePending, setUpdatePending] = useState();

  const navigate = useNavigate();

  var navigateFlag = false;

  // signed in
  useEffect(() => {
    if (auth.currentUser && !navigateFlag) {
      navigateFlag = true;
      navigate(location.state && location.state.fromInside ? -1 : "/", {
        state: location.state,
      });
    }
  }, [auth]);

  // signed up
  useEffect(() => {
    if (pending && !updatePending) {
      if (error) {
        // error handling - signed up but user record cannot be created in storage
      } else {
        // setPending(false);
        sessionStorage.setItem("token", JSON.stringify(auth.currentUser));
        // navigate("/", { state: location.state });
        if (!navigateFlag) {
          navigateFlag = true;
          navigate(location.state && location.state.fromInside ? -1 : "/", {
            state: location.state,
          });
        }
      }
    }
  }, [updatePending]);

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: isMobile() ? '20px' : '40px',
    paddingTop: '10px',
    paddingLeft: '0px',
    paddingRight: '0px',
    alignItems: 'center',
    textAlign: 'center',
  };

  const buttonContainerStyle = {
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const buttonStyle = {
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    height: '63px',
    // backgroundColor: '#FFFFFF',
  };

  const appStoreButtonStyle = {
    ...buttonStyle,
    color: '#000000',
  };

  const googlePlayButtonStyle = {
    ...buttonStyle,
    color: '#FFFFFF',
  };

  const imageStyle = {
    height: '63px',
    marginRight: '10px',
  };

  const separatorStyle = {
    width: '100%',
    height: '1px',
    backgroundColor: '#ddd',
    marginTop: '50px',
    marginBottom: '0px',
  };

  const darkBlueColor = '#3B3F4E'
  const lightBlueColor = '#5D677F'
  const orangeColor = '#E35335'
  const sunsetOrange = '#FA5F55'

  function setPageLayout() {
    if (pending) {
      return loading();
      // } else if (error) {
      // show error or do something else
      // return "error";
    } else {
      return (
        <div
          className="login col col-10 col-md-6 mx-auto"
          id="firebaseui-auth-container"
        >
          <div style={containerStyle}>
          <h2 style={{ fontSize: '1.5em', marginBottom: '40px', fontStyle: 'italic', color: darkBlueColor, textAlign: 'center', whiteSpace: isMobile() ? 'normal' : 'nowrap' }}>Download our mobile apps to get started.</h2>
          <div style={buttonContainerStyle}>
            <a href="https://apps.apple.com/us/app/rated10/id6448967860" style={appStoreButtonStyle}>
              <Image src={AppStoreButtonLogo} alt="Apple Logo" style={imageStyle} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.rated10.app&hl=en_US" style={googlePlayButtonStyle}>
              <Image src={GooglePlayStoreButtonLogo} alt="Google Play Logo" style={imageStyle} />
            </a>
          </div>
        </div>
        </div>
      );
    }
  }

  return setPageLayout();
};

export default Login;
